<template>
  <div id="cap-content" class="container-sm mt-sm-5">
    <div><a href="/admin">Return to Admin</a></div>
    <div id="cap-form" class="row p5">
      <div class="col-12" style="text-align: left">
        <div id="cap-form-header" style="text-align: center">
          <h1>Articles by Grade and Pathway</h1>
        </div>
        <div id="success-links">
          <div v-for="grade in admin_article_dict" :key="grade.grade_key">
            <div v-for="pathway in grade.pathway_dict" :key="pathway.pathway_key">
              <div style="margin: 1rem 0 0 1rem;"><h2>{{grade.grade_title}} / {{ pathway.pathway_title }}</h2></div>
              <div style="margin: 0 0 1rem 1rem;">{{ pathway.grade_pathway_title }}</div>
              <div v-for="article in pathway.article_dict" :key="article.article_key">
                <div style="margin: 1rem 0 0 3rem;">{{article.article_title}}</div>
                <div style="margin: 0 0 0 5rem;"><a target="_blank" :href="`/${lang_info.lang_code}/article/${article.article_key}`">({{lang_info.lang_code}}) {{ article.article_key }}</a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';

export default {
  name: "AdminArticlesByGradePathway",
  data() {
    return {
      data: '',
      lang_stanza_key: 'article',
      lang_stanza: {}, 
      lang_info: {}
    };
  },
  async created() {

    this.lang_info = await this.getLangInfo();
    this.lang_stanza = await this.getLangStanza({
      "lang_code": this.lang_info["lang_code"],
      "lang_stanza_key": this.lang_stanza_key
    });

    let error_list;
    let param_dict = {'group_by': 'grade_pathway', 'lang_code': this.lang_info["lang_code"]};
    error_list = await this.get_admin_articles(param_dict);
    if (error_list.length > 0) {
      error_list.forEach(err_item => {
      })
    }
  },
  methods: {
    ...mapActions('admin', ['get_admin_milestones', 'get_admin_articles']),
    ...mapActions('language', ['getLangInfo', 'getLangStanza'])
  },
  computed: {
    ...mapState('admin', ['admin_milestone_dict', 'admin_article_dict']),
  },
}
</script>


<style lang="scss" scoped>

.container {
  max-width: 1475px !important;
}

.container-sm {
  background-color: white;
  max-width: 1100px;
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 10%;
}

#cap-input {
  background: #ffffff;
  color: rgb(0, 0, 0);

  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

.prism-editor__textarea:focus {
  outline: none;
}

#cap-form-header {
  padding-bottom: 30px;
  padding-top: 30px;
}

#linecount {
  border: 1px solid lightgray;
  margin-top: 10px;
  padding: 10px;
}

#validation-status {
  margin-top: 5px;
  padding: 10px 10px 0 0;
}

#validation-errors {
  color: #960547;
  margin-top: 5px;
  padding: 0 10px 10px 10px;
}

#validation-text-highlight {
  border: 1px solid lightgray;
  margin-top: 10px;
  padding: 10px;
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
}

#cap-buttons {
  margin-top: 15px;
  margin-bottom: 30px;
  text-align: center;
}

</style>
