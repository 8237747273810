<template>
  <div id="cap-content" class="container-sm mt-sm-5">
    <div id="cap-form" class="row p5">
      <div class="col-12" style="text-align: left">
        <div id="cap-form-header" style="text-align: center">
          <h1>TomorrowReadyTexas Admin</h1>
        </div>
        <div>
          <a :href="`/admin/cap`">CAP - Content Authoring Portal</a>
        </div>
        <div>
          <a :href="`/admin/milestones-by-display-order`">Milestones by Display Order</a>
        </div>
        <div>
          <a :href="`/admin/articles-by-grade-pathway`">Articles by Grade and Pathway</a>
        </div>
        <div>
          <a :href="`/admin/articles-by-milestone`">Articles by Milestone</a>
        </div>
        <div>
          <a :href="`/admin/articles-by-milestone-strategy`">Articles by Milestone and Strategy</a>
        </div>
        <div>
          <a :href="`/admin/articles-by-key`">Articles by Key</a>
        </div>
        <div>
          <a :href="`/admin/articles-by-tag`">Articles by Tag</a>
        </div>
        <!--
        <div>
          <a :href="`/admin/articles-by-tag`">Articles by Tag</a>
        </div>
        -->
      </div>
    </div>

  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';

export default {
  name: "Admin",
  data() {
    return {
      data: '',
      lang_stanza_key: 'article',
      lang_stanza: {}, 
      lang_info: {}
  };
  },
  methods: {
      ...mapActions('language', ['getLangInfo', 'getLangStanza'])
  },
  computed: {
  },
}
</script>

<style lang="scss" scoped>

.container {
  max-width: 1475px !important;
}

.container-sm {
  background-color: white;
  max-width: 1100px;
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 10%;
}

#cap-input {
  background: #ffffff;
  color: rgb(0, 0, 0);

  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

.prism-editor__textarea:focus {
  outline: none;
}

#cap-form-header {
  padding-bottom: 30px;
  padding-top: 30px;
}

#linecount {
  border: 1px solid lightgray;
  margin-top: 10px;
  padding: 10px;
}

#validation-status {
  margin-top: 5px;
  padding: 10px 10px 0 0;
}

#validation-errors {
  color: #960547;
  margin-top: 5px;
  padding: 0 10px 10px 10px;
}

#validation-text-highlight {
  border: 1px solid lightgray;
  margin-top: 10px;
  padding: 10px;
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
}

#cap-buttons {
  margin-top: 15px;
  margin-bottom: 30px;
  text-align: center;
}

</style>
