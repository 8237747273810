<template>
  <div>
  <div id="cap-content" class="container-sm mt-sm-5">
    <div><a href="/admin">Return to Admin</a></div>
    <div id="cap-form" class="row p5">
      <div class="col-12" style="text-align: left">
        <div id="cap-form-header" style="text-align: center">
          <h1>Content Authoring Portal</h1>
        </div>

        <b-alert
            style="position: relative"
            :show="alert"
            dismissible
            @dismissed="alert=false"
            :variant="alertType">
          <p v-if="alertType === 'danger'">ERROR: {{ message }} at line {{ line }}</p>
          <p v-else>{{ message }}</p>
        </b-alert>

        <div>Paste full <strong>Milestone XML</strong> or <strong>Milestone Sort</strong> content into box. Then <strong>Validate</strong> only, or <strong>Validate + Save</strong>
          to store document.
          <br/>
          <strong>Clear</strong> to reset input form.
          <br/>
          <strong>Delete</strong> to remove <strong>Milestone XML</strong> with all of its article content completely from storage.
          <strong style="color: #FF0000FF;"><em>Note that deleting a Milestone will also delete any associated user progress.</em></strong>
        </div>
        <b-form-textarea
            id="cap-input"
            v-model="data"
            :formatter="highlighter"
            rows="12"
            no-resize>
        </b-form-textarea>
        <div id="linecount"> Lines: {{ lineCount }}</div>
        <div id="cap-buttons">
          <b-button style="margin-right: 30px" variant="info" id="validate" @click="validate(data)">Validate</b-button>
          <b-button style="margin-right: 30px" variant="success" id="submit" :disabled="disable_button_submit"
                    @click="upload(data)">Validate + Save
          </b-button>
          <b-button style="margin-right: 30px" variant="warning" id="clear"
                    @click="clearPanel">Clear
          </b-button>
          <b-button style="margin-right: 0px" variant="danger" id="delete_milestone"
                    @click="deleteMilestone(data)">Delete
          </b-button>
        </div>
        <div v-show="show_val_status" id="validation-status">
          <p v-if="validationData.status==='error'">
            <span style="color:red">{{ result_message }}</span>
          </p>
          <p v-else>
            <span style="color:green">{{ result_message }}</span>
          </p>
        </div>
        <div v-show="show_val_errors" id="validation-errors">
          <div v-for="(err, err_idx) in validationData.error_list" :key="err_idx">
            {{ err.error_message }}
          </div>
        </div>
        <div v-show="show_success_links" id="success-links">
          <div v-for="(item, item_idx) in resultLinks.links_list" :key="item_idx">
            <p>
              Language: {{ item.lang_code }}
            </p>
            <p style="margin-left: 3rem;">
              Action Plan:
              <a target="_blank" :href="`/${item.lang_code}/actionplan/${item.grade_key}/${item.pathway_key}/`">{{ item.grade_key }}
                {{ item.pathway_key }}</a>
            </p>
            <p style="margin-left: 5rem;">
              Articles:
            </p>
            <div style="margin-left: 8rem;" v-for="(article_item, a_item_idx) in item.article_item_list" :key="a_item_idx">
              <p>
                <a target="_blank" :href="`/${item.lang_code}/article/${article_item.article_key}`">{{ article_item.article_title }}</a>
              </p>
              <p>
              </p>
            </div>
          </div>
        </div>
        <div v-show="show_val_text_highlight" id="validation-text-highlight"> Validation text-highlight<p/>
          {{ lineCount }}
        </div>
      </div>
    </div>

  </div>
    <Footer></Footer>
  </div>
</template>

<script>
import parser from "fast-xml-parser";
import {mapActions, mapState} from 'vuex';

export default {
  name: "ContentAuthoringPortal",
  data() {
    return {
      data: '',
      message: '',
      line: '',
      alert: false,
      alertType: '',
      show_val_status: false,
      show_val_errors: false,
      show_success_links: false,
      show_val_text_highlight: false,
      disable_button_submit: false,
      result_message: "",
      lang_stanza_key: 'article',
      lang_stanza: {}, 
      lang_info: {}
    };
  },
  async created() {

    this.lang_info = await this.getLangInfo();
    this.lang_stanza = await this.getLangStanza({
      "lang_code": this.lang_info["lang_code"],
      "lang_stanza_key": this.lang_stanza_key
    });

  },
  methods: {
    ...mapActions('content', ['persistXmlContent',
      'validateXmlContent',
      'evaluateCapContentType',
      'deleteMilestoneContent',
      'persistMilestoneSort',
      'validateMilestoneSort']),
    ...mapActions('language', ['getLangInfo', 'getLangStanza']),
    clearPanel() {
      this.data = "";
      this.result_message = "";
      this.show_val_errors = false;
      this.show_val_status = false
      this.show_success_links = false;
    },
    async deleteMilestone(data) {
        this.result_message = "";
        this.show_val_errors = false;
        this.show_val_status = false;
        this.show_success_links = false;
        if (!data) return;
        let doc = {"doc": data}
        await this.validateXmlContent(doc);
        this.show_val_status = true;
        if (this.validationData.status === 'error') {
          this.show_val_errors = true;
          // TODO -- show more detailed output
          this.show_val_text_highlight = false;
          this.disable_button_submit = false;
          this.result_message = "Validation failed.";
        } else {
          this.show_val_status = true;
          this.show_val_errors = false;
          this.show_success_links = false;
          this.show_val_text_highlight = false;
          this.disable_button_submit = false;
          this.result_message = "Processing...";
          let doc = {"doc": data}
          await this.deleteMilestoneContent(doc);
          this.result_message = "Success! Content deleted.";
          this.show_success_links = false;
        }
    },
    async validate(data) {
      this.result_message = "";
      this.show_val_errors = false;
      this.show_val_status = false;
      this.show_success_links = false;
      if (!data) return;
      let result_obj = await this.evaluateCapContentType({"data": data});
      let cap_content_type = result_obj['cap_content_type'];
      if (cap_content_type === 'milestone-sort') {
        let doc = {
          "milestone-sort": data,
          "lang_code": this.lang_info.lang_code
        }
        await this.validateMilestoneSort(doc)
      } else {
        let doc = {"doc": data}
        await this.validateXmlContent(doc)
      }
      this.show_val_status = true;
      if (this.validationData.status === 'error') {
        this.show_val_errors = true;
        // TODO -- show more detailed output
        this.show_val_text_highlight = false;
        this.disable_button_submit = false;
        this.result_message = "Validation failed.";
      } else {
        this.show_val_status = true;
        this.show_val_errors = true;
        this.show_val_text_highlight = false;
        this.disable_button_submit = false;
        this.result_message = "Success! Okay to save or delete document.";
      }


      /*
      if(response === true)
      {
          this.message = "XML is Valid!"
          this.alert = true;
          this.alertType = 'success'
      }
      else
      {
          this.message = response.err.msg;
          this.line = response.err.line;
          this.alert = true;
          this.alertType = 'danger'
      }
      */
    },
    highlighter(value) {
      return value;
      // return value.toLowerCase();
    },
    async upload(data) {
      this.result_message = "";
      this.show_val_errors = false;
      this.show_val_status = false;
      this.show_success_links = false;
      if (!data) return;
      let result_obj = await this.evaluateCapContentType({"data": data});
      let cap_content_type = result_obj['cap_content_type'];
      if (cap_content_type === 'milestone-sort') {
        let doc = {
          "milestone-sort": data,
          "lang_code": this.lang_info.lang_code
        }
        await this.validateMilestoneSort(doc)
      } else {
        let doc = {"doc": data}
        await this.validateXmlContent(doc)
      }
      this.show_val_status = true;
      if (this.validationData.status === 'error') {
        this.show_val_errors = true;
        // TODO -- show more detailed output
        this.show_val_text_highlight = false;
        this.disable_button_submit = false;
        this.result_message = "Validation failed.";
      } else {
        this.show_val_status = true;
        this.show_val_errors = false;
        this.show_success_links = false;
        this.show_val_text_highlight = false;
        this.disable_button_submit = false;
        this.result_message = "Processing...";

        if (cap_content_type === 'milestone-sort') {
          let doc = {
            "milestone-sort": data,
            "lang_code": this.lang_info.lang_code
          }
          await this.persistMilestoneSort(doc)
        } else {
          let doc = {"doc": data}
          await this.persistXmlContent(doc)
        }
        this.result_message = "Success! Content uploaded.";
        this.show_success_links = true;
      }
    }
  },
  computed: {
    ...mapState('content', ['resultLinks', 'validationData']),
    lineCount() {
      // Return number of lines using regex if not empty
      return this.data.length ? this.data.split(/\r\n|\r|\n/).length : 0;
    },
  },
}
</script>

<style lang="scss" scoped>

.container {
  max-width: 1475px !important;
}

.container-sm {
  background-color: white;
  max-width: 1100px;
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 10%;
}

#cap-input {
  background: #ffffff;
  color: rgb(0, 0, 0);

  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

.prism-editor__textarea:focus {
  outline: none;
}

#cap-form-header {
  padding-bottom: 30px;
  padding-top: 30px;
}

#linecount {
  border: 1px solid lightgray;
  margin-top: 10px;
  padding: 10px;
}

#validation-status {
  margin-top: 5px;
  padding: 10px 10px 0 0;
}

#validation-errors {
  color: #960547;
  margin-top: 5px;
  padding: 0 10px 10px 10px;
}

#validation-text-highlight {
  border: 1px solid lightgray;
  margin-top: 10px;
  padding: 10px;
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
}

#cap-buttons {
  margin-top: 15px;
  margin-bottom: 30px;
  text-align: center;
}

</style>
